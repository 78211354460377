nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10vw;
    height: var(--space68);
    /*border-bottom: 1px solid #00000010;*/
    position: fixed;
    z-index: 99;
    left: 0;
    right: 0;
    background-color: var(--white-pure);
    transition: 100ms ease;
}

.footer {
    /*border-top: 3px solid var(--black-4);*/
    padding: 24px 10vw 24px 10vw;
    /*margin: 100px 10vw 0 10vw;*/
    /*padding: 48px 40px;*/
    display: flex;
    flex-direction: column;
    padding-right: 80px;
    /*border-radius: 42px 42px 0 0;*/
    overflow: hidden;
    /*box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.08);*/
}

.footer-mobile {
    /*margin: 60px 0 0 0;*/
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    /*border-radius: 42px 42px 0 0;*/
    overflow: hidden;
    /*box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.08);*/
}

.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 var(--space100) 0 0;
}

.footer-bottom {
    border-top: 1px solid var(--black-10);
    height: var(--space100);
    margin: var(--space80) var(--space100) 0 var(--space100);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
