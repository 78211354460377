.modal-new-open {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1050 !important;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 150ms ease;
}

.modal-content {
    background-color: var(--white-pure);
    padding: var(--space24);
    position: relative;
    overflow: visible;
    box-shadow: 0 12px 20px var(--black-20);
}

.modal-content-xs {
    min-width: 300px;
    max-width: 300px;
}

.modal-content-sm {
    min-width: 383px;
    max-width: 383px;
}

.modal-content-md {
    min-width: 300px;
    max-width: min(500px, 95vw);
}

.modal-content-lg {
    min-width: 800px;
    max-width: 800px;
}

.modal-content-xl {
    width: 1140px;
    max-width: min(1140px, 95vw);
}

.modal-content-full {
    width: 98vw;
    height: 98vh;
    display: flex;
    flex-direction: column;
}

.modal-content-full-mobile {
    width: 80vw;
    min-height: 50vh;
}

.modal-content-cover {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.fade-in-on-scroll {
    opacity: 0;
    transition: all 800ms ease-out;
    transform: translateY(40px);
}

.fade-in-on-scroll.visible {
    opacity: 1;
    transform: translateY(0px);
}