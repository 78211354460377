
button {
    outline: none !important;
    stroke: none !important;
    border-width: 0 !important;
}
.primary-button {
    background: var(--primary);
    border-radius: 100px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    padding: 0px 32px;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    transition: 150ms ease;
}

.primary-button:hover {
    transform: translateY(-1px);
    background-color: #033363;
}

.primary-button-sm {
    background: var(--primary);
    border-radius: 100px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0px 14px;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    transition: 150ms ease;
}

.primary-button-sm:hover {
    transform: translateY(-1px);
    background-color: #033363;
}

.secondary-button {
    background: var(--black-8);
    border-radius: 100px;
    color: #0A0A0A;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    padding: 0px 32px;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    transition: 150ms ease;
}

.secondary-button:hover {
    transform: translateY(-1px);
    background-color: var(--black-10);
}

.button-sm {
    height:32px;
    padding:0 12px;
}


/*button {*/
/*	 position: relative;*/
/*	 display: inline-block;*/
/*	 cursor: pointer;*/
/*	 outline: none;*/
/*	 border: 0;*/
/*	 vertical-align: middle;*/
/*	 text-decoration: none;*/
/*	 background: transparent;*/
/*	 padding: 0;*/
/*	 font-size: inherit;*/
/*	 font-family: inherit;*/
/*}*/
/* button.learn-more {*/
/*	 width: 12rem;*/
/*	 height: auto;*/
/*}*/
/* button.learn-more .circle {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 position: relative;*/
/*	 display: block;*/
/*	 margin: 0;*/
/*	 width: 3rem;*/
/*	 height: 3rem;*/
/*	 background: var(--primary);*/
/*	 border-radius: 1.625rem;*/
/*}*/
/* button.learn-more .circle .icon {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 position: absolute;*/
/*	 top: 0;*/
/*	 bottom: 0;*/
/*	 margin: auto;*/
/*	 background: #fff;*/
/*}*/
/* button.learn-more .circle .icon.arrow {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 left: 0.625rem;*/
/*	 width: 1.125rem;*/
/*	 height: 0.125rem;*/
/*	 background: none;*/
/*}*/
/* button.learn-more .circle .icon.arrow::before {*/
/*	 position: absolute;*/
/*	 content: '';*/
/*	 top: -0.25rem;*/
/*	 right: 0.0625rem;*/
/*	 width: 0.625rem;*/
/*	 height: 0.625rem;*/
/*	 border-top: 0.125rem solid #fff;*/
/*	 border-right: 0.125rem solid #fff;*/
/*	 transform: rotate(45deg);*/
/*}*/
/* button.learn-more .button-text {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 position: absolute;*/
/*	 top: 0;*/
/*	 left: 0;*/
/*	 right: 0;*/
/*	 bottom: 0;*/
/*	 padding: 0.75rem 0;*/
/*	 margin: 0 0 0 1.85rem;*/
/*	 color: var(--primary);*/
/*	 font-weight: 700;*/
/*	 line-height: 1.6;*/
/*	 text-align: center;*/
/*	 text-transform: uppercase;*/
/*}*/
/* button:hover .circle {*/
/*	 width: 100%;*/
/*}*/
/* button:hover .circle .icon.arrow {*/
/*	 background: #fff;*/
/*	 transform: translate(1rem, 0);*/
/*}*/
/* button:hover .button-text {*/
/*	 color: #fff;*/
/*}*/
/* @supports (display: grid) {*/
/*	 body {*/
/*		 display: grid;*/
/*		 grid-template-columns: repeat(4, 1fr);*/
/*		 grid-gap: 0.625rem;*/
/*		 grid-template-areas: ". main main ." ". main main .";*/
/*	}*/
/*	 #container {*/
/*		 grid-area: main;*/
/*		 align-self: center;*/
/*		 justify-self: center;*/
/*	}*/
/*}*/
