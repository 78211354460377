/* CreateChallengeEndDate.css */

.react-calendar {
    width: 100%;
    border: 1px solid #cbd5e1; /* slate-300 */
    border-radius: 0.5rem; /* rounded-lg */
    padding: 1rem; /* py-1 px-2 */
    background-color: white;
    font-family: 'Karla', sans-serif !important;
}

.react-calendar__tile--now {
    background: #93c5fd; /* blue-300 */
    color: coral !important;
}

.react-calendar__tile--active {
    background: #3b82f6; /* blue-500 */
    color: white !important;
    border-radius: 12px;
}

.react-calendar__tile--hasActive:hover,
.react-calendar__tile--active:focus {
    background: #3b82f6; /* blue-500 */
}

.react-calendar__tile {
    text-align: center;
    padding: 0.5rem 0.75rem; /* py-2 px-3 */
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.react-calendar__tile:disabled {
    background-color: #e5e7eb; /* slate-200 */
    color: #9ca3af; /* slate-500 */
}

.react-calendar__tile:enabled:hover {
    border-radius: 12px;
    background-color: #f3f4f6; /* slate-100 */
}

.react-calendar__tile--active:hover {
    background: #3b82f6 !important; /* blue-500 */
    color: white;
}

.react-calendar__navigation button {
    color: #374151; /* slate-700 */
    min-width: 44px;
    background: none;
    font-size: 1.2rem; /* text-xl */
    margin-top: 0.5rem;
    border: none;
    outline: none;
    cursor: pointer;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f3f4f6; /* slate-100 */
}

.react-calendar__month-view__weekdays__weekday {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #6b7280; /* slate-500 */
    border-bottom: none; /* Remove dotted underline */
}

.react-calendar__month-view__days__day--weekend {
    color: inherit; /* Remove red color for weekends */
}
