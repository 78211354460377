@import url(https://fonts.googleapis.com/css?family=Open+Sans:600);


.text {
    position: absolute;
    /*width: 400px;*/
    /*left: 50%;*/
    /*margin-left: -225px;*/
    height: 40px;
    /*top: 50%;*/;
    width: 800px;
}

p {
    display: inline-block;
    vertical-align: top;
    margin: 0;
}

.word {
    position: absolute;
    min-width: 800px;
    opacity: 0;
}

.letter {
    display: block;
    position: relative;
    float: left;
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
}

.empty_space {
    width: 25px !important;
    background-color: red;
}

.letter.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.letter.behind {
    transform: rotateX(-90deg);
}

.letter.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.wisteria {
    /*color: #6A93C6;*/
    color: var(--primary);
}

.belize {
    /*color: #F0A042;*/
    color: var(--primary);
}

.pomegranate {
    /*color: #956DE6;*/
    color: var(--primary);
}

.green {
    /*color: #CC93E2;*/
    color: var(--primary);
}

.midnight {
    /*color: #A2BCDC;*/
    color: var(--primary);
}
