@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --d: 5500ms;
    --angle: 90deg;
    --gradX: 100%;
    --gradY: 50%;
    --c1: rgba(168, 239, 255, 1);
    --c2: rgba(168, 239, 255, 0.1);
}


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
    height: 0;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.hero-wrapper {
    opacity: 0.3;
    background-image: radial-gradient(#054A91 0.45px, #fafcff 0.45px);
    background-size: 9px 9px;
}

.footer-wrapper {
    opacity: 1;
    background: rgb(5, 74, 145);
    background: radial-gradient(circle, rgba(5, 74, 145, 1) 52%, rgba(5, 98, 145, 1) 100%);
}

.footer-logo {
    filter: brightness(0) invert(0);
}

.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.hero-img {
    /*backdrop-filter: drop-shadow(10px 10px 10px var(--primary));*/
    /*box-shadow: 10px 10px 10px var(--primary);*/
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 4px);
    }

    100% {
        transform: translate(0, -0px);
    }
}


/*.box {*/
/*	border: 0.2rem solid;*/
/*    border-radius: 12px;*/
/*	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;*/
/*	animation: borderRotate var(--d) linear infinite forwards;*/
/*}*/

/*.box:nth-child(2) {*/
/*	border-image: radial-gradient(ellipse at var(--gradX) var(--gradY), var(--c1), var(--c1) 10%, var(--c2) 40%) 30;*/
/*	animation: borderRadial var(--d) linear infinite forwards;*/
/*}*/

/*@keyframes borderRotate {*/
/*	100% {*/
/*		--angle: 420deg;*/
/*	}*/
/*}*/

/*@keyframes borderRadial {*/
/*	20% {*/
/*		--gradX: 100%;*/
/*		--gradY: 50%;*/
/*	}*/
/*	40% {*/
/*		--gradX: 100%;*/
/*		--gradY: 100%;*/
/*	}*/
/*	60% {*/
/*		--gradX: 50%;*/
/*		--gradY: 100%;*/
/*	}*/
/*	80% {*/
/*		--gradX: 0%;*/
/*		--gradY: 50%;*/
/*	}*/
/*	100% {*/
/*		--gradX: 50%;*/
/*		--gradY: 0%;*/
/*	}*/
/*}*/

.profile-card {
    transition: 200ms ease;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.profile-card:hover {
    animation-name: hover-float;
    animation-duration: 3s;
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.18);
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes hover-float {
    0% {
        transform: translateY(0px);
    }

    10% {
        transform: translateY(-3px);
    }

    50% {
        transform: translateY(3px);
    }

    100% {
        transform: translateY(-0px);
    }
}

.next-button {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid var(--black-10);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 150ms ease;
}

.next-button:hover {
    background-color: var(--black-10);
    transform: translateY(-2px);
}

.next-button-disabled {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid var(--black-10);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*.page-wrapper {*/
/*    scroll-snap-type: y mandatory;*/
/*    overflow-y: scroll;*/
/*    height: 100vh;*/
/*}*/

section {
    scroll-snap-align: start;
    min-height: 100vh;
}

.timeline-label {
    width: 80px;
    height: 80px;
    background-color: var(--white-pure);
    border: 1px solid var(--black-10);
    border-radius: 100%;
    top: 50%;
    left: -40px;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.timeline-label-mobile {
    width: 60px;
    height: 60px;
    background-color: var(--white-pure);
    border: 1px solid var(--black-10);
    border-radius: 100%;
    top: 50%;
    left: -31px;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.timeline-start-dot {
    width: 13px;
    height: 13px;
    border-radius: 100%;
    top: -8px;
    left: -5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.timeline-start-dot-mobile {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    top: -8px;
    left: -3.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.timeline-end-dot {
    width: 13px;
    height: 13px;
    border-radius: 100%;
    bottom: -8px;
    left: -5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.timeline-end-dot-mobile {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    bottom: -8px;
    left: -3.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.spinner {
    height: 1em;
    width: 1em;
    border: 1px solid var(--white-20);
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-left-color: var(--white-50);
    animation: rotate 1.2s linear infinite;
    box-sizing: border-box;
}

.spinner-light {
    height: 1em;
    width: 1em;
    border: 1px solid var(--black-20);
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-left-color: var(--black-50);
    animation: rotate 1.2s linear infinite;
    box-sizing: border-box;
}

.logo-spin {
    width: 80px;
    aspect-ratio: 1;
    animation: rotate 10.2s linear infinite;
}


@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

.kyc-spin-center {
    animation: rotate 10s linear infinite;
}


.donate-input {
    outline: none !important;
    border: none !important;
    font-size: 80px;
    width: auto !important;
    text-align: center;
    border-radius: 8px;
}

.donate-input:hover,
.donate-input:focus {
    background-color: #00000010;
}

.large-input {
    outline: none !important;
    font-size: 80px;
    /*width: auto !important;*/
    width: 100%;
    text-align: center;
    border-radius: 16px;
    /*background-color: #f1f5f9;*/
    line-height: 0;
    border: 1px solid #cbcfd3;
}

.large-input:hover,
.large-input:focus {
    background-color: #f1f5f9;
}


.feed-card {
    background-color: white;
    cursor: pointer;
}

.feed-card:hover {
    background-color: #f5f5f5;
}


.nav-menu-text:hover {
    color: #ffffff50 !important;
}

.nav-menu-button:hover {
    background-color: #ffffff10 !important;
}

.npo-nav-card {
    border: 1px solid #00000020;
}

.npo-nav-card:hover {
    background-color: white;
    border: 1px solid #054A91;
}


.search-input {
    background-color: white;
    border-radius: 100px;
    border: 1px solid #00000020;
    outline: none;
    height: 42px;
    padding: 0 24px 0 60px;
}


.nav-icon {
    color: var(--primary);
    cursor: pointer;
}

.nav-icon:hover {
    color: #1f77d0;
}


.nav-link {
    text-decoration: none !important;
    color: #1d1d1d90
}

.nav-link:hover {
    color: var(--primary);
}

.bg-brand {
    background: white;
    background-image: radial-gradient(#00000020 1px, transparent 0);
    background-size: 12px 12px;
    background-position: -19px -19px;
}

.uploaded-file {
    /*padding: 4px;*/
    position: relative;
}

.uploaded-file:hover {
    /*background-color: var(--base-white-20);*/
}

.uploaded-file div {
    opacity: 0;
    cursor: pointer;
    color: white;
    transition: 300ms ease-in-out;
    transform: translateY(20px);
}

.uploaded-file:hover div {
    opacity: 1;
    transform: translateY(0px);
}

/* CSS For animations */

.fade-enter {
    opacity: 0;
    transform: translateX(-20px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.fade-exit {
    opacity: 1;
    transform: translateX(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}