.public-DraftEditorPlaceholder-inner {
    position: absolute;
    color: #aaaaaa;
    pointer-events: none;
}

.mnw6qvm {
    border-radius: 16px !important; /* Adjust as needed */
    /* Add any other styles you want for the container here */
}

.userMention {
    @apply text-blue-500; /* Tailwind class for blue text */
}

.nonProfitMention {
    @apply text-orange-500; /* Tailwind class for orange text */
}
