
body {
    font-family: 'Karla', sans-serif;
}

input {
    font-family: inherit;
    font-weight: 500;
    font-size: inherit;
}

textarea {
    font-family: inherit;
    font-weight: 500;
    font-size: inherit;
}

.heading1 {
    font-size: 64px;
    font-weight: 800;
    line-height: 1.2;
}

.heading1b {
    font-size: 48px;
    font-weight: 800;
}

.heading2 {
    font-size: 42px;
    font-weight: 800;
    line-height: 1.4;
}

.heading3 {
    font-size: 32px;
    font-weight: 800;
}

.heading4 {
    font-size: 26px;
    font-weight: 800;
}

.title1 {
    font-size: 24px;
    font-weight: bold;
}

.title2 {
    font-size: 20px;
    font-weight: bold;
}

.body1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.8;
}

.body1-bold {
    font-size: 18px;
    font-weight: bold !important;
}

.body2 {
    font-size: 16px;
    font-weight: 500;
}

.body2-bold {
    font-size: 16px !important;
    font-weight: bold !important;
}

.body3 {
    font-size: 14px;
    font-weight: 500;
}

.body3-bold {
    font-size: 14px !important;
    font-weight: bold !important;
}

.body4 {
    font-size: 10px;
    font-weight: 500;
}

.body4-bold {
    font-size: 10px;
    font-weight: 700;
}


.body3-bold {
    font-size: 12px;
    font-weight: bold;
}

/* font size for viewport width up to 600px */
@media screen and (max-width: 760px) {
    .heading2 {
        font-size: 32px;
    }

    .title1 {
        font-size: 18px;
    }

    .body1, .body1-bold {
        font-size: 14px !important;
    }

    .body2, .body2-bold {
        font-size: 13px !important;
    }

    .body3, .body3-bold {
        font-size: 11px !important;
    }
}

/* font size for viewport width between 600px and 1200px */
@media screen and (min-width: 760px) and (max-width: 1200px) {
    .heading2 {
        font-size: 42px;
    }

    .title1 {
        font-size: 24px;
    }

    .body1, .body1-bold {
        font-size: 18px;
    }

    .body2, .body2-bold {
        font-size: 16px;
    }

    .body3, .body3-bold {
        font-size: 14px;
    }
}

/* font size for viewport width above 1200px */
@media screen and (min-width: 1200px) {
    .heading2 {
        font-size: 42px;
    }

    .title1 {
        font-size: 24px;
    }

    .body1, .body1-bold {
        font-size: 18px;
    }

    .body2, .body2-bold {
        font-size: 16px;
    }

    .body3, .body3-bold {
        font-size: 14px;
    }
}

.uppercase {
    text-transform: uppercase;
}





