.animated-input {
    position: relative;

}

.animated-input__input {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    border: none;
    border-bottom: 2px solid #ddd;
    /*font-size: 18px;*/
    padding: 10px;
    outline: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-radius: 0px !important;
}

.animated-multiline-input__label {
    position: absolute;
    top: 15%;
    left: 10px;
    transform: translateY(-50%);
    /*font-size: 18px;*/
    color: #999;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

.animated-input__label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    /*font-size: 18px;*/
    color: #999;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

.animated-input__label--focused {
    top: -28%;
    left: 10px;
    /*font-size: 14px;*/
    color: #054A91;
    font-weight: 800;
}

.animated-input__label--filled {
    top: -24%;
    left: 10px;
    color: #999;
    font-weight: 800;
}

.animated-multiline-input__label--focused {
    top: -16%;
    left: 10px;
    /*font-size: 14px;*/
    color: #054A91;
    font-weight: 800;
}

.animated-multiline-input__label--filled {
    top: 0%;
    left: 10px;
    color: #999;
    font-weight: 800;
}

.animated-input__input:focus {
    border-bottom: 2px solid #054A91;
}



